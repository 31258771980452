$(function () {
  console.log('app is setup now, including Jquery');
});

$(document).ready(function() {
  $(".select2").select2({
    minimumInputLength: 3,
    minimumResultsForSearch: 1,
    theme: 'bootstrap-5'
  });

  $("select.select2").each(function(thing) {

    var select2Object = this;
    var data = select2Object.dataset
    var hiddenFieldId = data.hiddenId;

    if (hiddenFieldId) {

      var hiddenField = document.getElementById(hiddenFieldId);
      if (hiddenField && hiddenField.value) {

        var url = JSON.parse(data.ajax);
        var urlWeNeed = url.url.split('.')[0] + '/' + hiddenField.value + '.json'

        $.ajax({
          type: 'GET',
          url: urlWeNeed
        }).then(function (data) {
          // create the option and append to Select2
          var option = new Option(data.text, data.id, true, true);
          $(select2Object).append(option).trigger('change');

          $(select2Object).trigger({
            type: 'select2:select',
            params: {
                data: data
            }
          });
        });
      }
    }
  });
});
