// Entry point for the build script in your package.json
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

console.log('Hello World from Esbuild')

import Rails from "@rails/ujs"
import 'bootstrap/dist/js/bootstrap';
import './src/set-up-select-2';

Rails.start();
